document.querySelectorAll('.palette-item').forEach((element) => {
  element.addEventListener('click', () => {
      [...element.parentElement.children].forEach((sib) => sib.classList.remove('active'));
      element.classList.add('active');
      const color = element.querySelector('.palette-color').getAttribute('data-color');
      const dyeElement = element.parentElement.parentElement;
      const text = element.querySelector('.palette-text').innerHTML;
      if (color && dyeElement && text) {
          dyeElement.querySelector('.dye-palette-background').setAttribute('data-color', color);
          dyeElement.querySelector('.dye-palette-name').innerHTML = text;
      }
  });
});
