import '../scss/style.scss';
import WOW from '../../node_modules/wow.js/dist/wow.js';
import './map';
import './totop';
import './palette';
import Glide, {Controls, Autoplay, Swipe, Breakpoints} from '@glidejs/glide/dist/glide.modular.esm';

const glideSections = [];

document.addEventListener('DOMContentLoaded', function() {
  new WOW().init();

  new Glide('.glide-benefits', {
    type: 'carousel',
    autoplay: '3000',
  }).mount({Controls, Autoplay, Swipe});


  document.querySelectorAll('.glide-section').forEach((section) => {
    const glideSection = new Glide(section, {
      type: 'slider',
      perView: 4,
      animationDuration: 300,
      breakpoints: {
        1170: {
          perView: 3
        },
        660: {
          perView: 2
        },
        460: {
          perView: 1
        }
      }
    }).mount({Swipe, Breakpoints});

    glideSections.push(glideSection);
  });


  document.querySelector('#buy-online').addEventListener('click', () => {
    toggleMap();
  });

  document.querySelector('#buy-offline').addEventListener('click', () => {
    toggleMap();
  });

  if (/iPad|iPhone|iPod/.test(navigator.platform) || ('MacIntel' === navigator.platform && navigator.maxTouchPoints > 1)) {
    const elements = document.querySelectorAll('.fixed');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.backgroundAttachment = 'scroll';
    }
  }
}); //end document ready

//параллакс
window.addEventListener('scroll', function() {
  document.querySelectorAll('.parallax').forEach((item) => {
    item.style.backgroundPosition = '50% ' + window.scrollY / 2 + 'px, center top';
  });
});

document.querySelectorAll('a[href^="#"]').forEach(function(e) {
  e.addEventListener('click', function(e) {
    e.preventDefault();
    if (this.getAttribute('href') !== '#') {
      document.querySelector(this.getAttribute('href')).scrollIntoView({block: "start", behavior: "smooth"});
    }
  });
});


const sectionCompositions = document.querySelectorAll('.section-item');

window.addEventListener("scroll", event => {
  sectionCompositions.forEach((sectionComposition, index) => {
    const mainNavLinks = sectionComposition.querySelectorAll(".btn-dye");
    const sectionHeaderHeight = sectionComposition.querySelector('.section-item-header').offsetHeight;
    const buttonsHeight = sectionComposition.querySelector('.buttons-dye').offsetHeight;

  let fromTop = window.scrollY - sectionComposition.offsetTop + sectionHeaderHeight;

  mainNavLinks.forEach(link => {
    let section = document.querySelector(link.hash);

    if (
        section.offsetTop <= fromTop &&
        section.offsetTop + section.offsetHeight > fromTop
    ) {
      const slideId = document.querySelector(".buttons-dye a[href*=" + section.getAttribute('id') + "]").parentElement.getAttribute('data-glide-id');
      glideSections[index].go('=' + slideId);
    }
  });
})});


//отправка формы
const form = document.querySelector('#form');
const action = form.getAttribute('action');
const resultField = document.querySelector('#form-result');
const submitButton = document.querySelector('#form-submit');
const loader = document.querySelector('.loader');

function createMessage(text, status, parent) {
  let el = document.createElement("span");
  el.classList.add('form-result');

  if(status === 'error') {
    el.classList.add('form-result-error');
  } else if (status === 'success') {
    el.classList.add('form-result-success');
  }

  let node = document.createTextNode(text);
  el.appendChild(node);
  parent.appendChild(el);
}

grecaptcha.ready(function () {
  grecaptcha.execute('6LfR31ooAAAAAE8iS2Enm61Jo0Ys8t7fuy09vQE9', { action: 'contact' }).then(function (token) {
    var recaptchaResponse = document.getElementById('recaptchaResponse');
    recaptchaResponse.value = token;
  });
});


form.addEventListener('submit', function (e) {
  e.preventDefault();

  resultField.innerHTML = '';

  let request = new XMLHttpRequest();

  request.open('POST', action, true);

  let formData = new FormData(form);
  request.send(formData);

  loader.style.display = 'block';
  submitButton.disabled = true;

  request.onload = function() {
    let resp = this.responseText;
    console.log(resp);
    switch (resp) {
      case 'Success':
        createMessage('Ваше сообщение успешно отправлено', 'success', resultField);
        break;
      case 'Error':
        createMessage('Ошибка отправки формы', 'error', resultField);
        break;
      case 'Captcha Error':
        createMessage('Ошибка верификации ReCaptcha. Пожалуйста повторите попытку позже.', 'error', resultField);
        break;
      default:
        createMessage('Ваше сообщение успешно отправлено', 'success', resultField);
    }
    setTimeout(function(){
      submitButton.disabled = false;
    },5000);
    loader.style.display = 'none';
  };

  request.onerror = function() {
    createMessage('Ошибка отправки формы', 'error', resultField);
  };

});

//Переключение с карты на список магазинов и обратно
function toggleMap() {
  const buyOnline = document.querySelector('#buy-online');
  const buyOffline = document.querySelector('#buy-offline');
  const mapBlock = document.querySelector('#map');
  const shopsList = document.querySelector('#list');

  buyOffline.classList.toggle('active');
  buyOnline.classList.toggle('active');
  shopsList.classList.toggle('display-none');
  mapBlock.classList.toggle('display-none');
}

