const scrollToTopBtn = document.querySelector('#toTop');

document.addEventListener('scroll', handleScroll);
scrollToTopBtn.addEventListener('click', scrollToTop);

function handleScroll() {
  const scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  const GOLDEN_RATIO = 0.1;

  if ((document.documentElement.scrollTop / scrollableHeight) > GOLDEN_RATIO) {
    scrollToTopBtn.style.display = 'block';
  } else {
    scrollToTopBtn.style.display = 'none';
  }
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}
