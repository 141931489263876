import * as mapData from './mapdata.json';

//инициализируем карту
ymaps.ready(init);

function init() {
  const map = new ymaps.Map('map', {
    center: '55.755864, 37.617698',
    zoom: 12,
    behaviors: ['drag'],
    controls: ['geolocationControl', 'zoomControl'],
  },
  );

  const data = Array.from(mapData);

  const searchControl = new ymaps.control.SearchControl({
    options: {
      provider: 'yandex#search',
      noPlacemark: true,
      noPopup: true,
      noSuggestPanel: true,
      float: 'right',
    },
  });

  map.controls.add(searchControl);

  makePlacemarks(data, map);

  //двигаем карту к местоположению пользователя
  const location = ymaps.geolocation;

  location.get({
    mapStateAutoApply: true,
  })
      .then(
          function(result) {
            const userCoodinates = result.geoObjects.get(0).geometry.getCoordinates();

            map.panTo(
                userCoodinates, {
                  duration: 2000,
                  flying: false,
                },
            );
          },
          function(err) {
            console.log('Ошибка: ' + err);
          },
      );
} //init end

function makePlacemarks(mapData, map) {
  //создаем точки на карте на основе данных
  const placemarks = [];

  for (let i = 0; i < mapData.length; i++) {
    let balloonText;
    const site = mapData[i].site ? mapData[i].site : '';
    const address = mapData[i].parsed_addr ? mapData[i].parsed_addr : '';
    const phone = mapData[i].phones[0] ? mapData[i].phones[0] : '';

    if (site) {
      balloonText = phone +'<br>' + address + '<br><a href="' + site + '" target="_blank">Перейти в каталог</a>';
    } else {
      balloonText = phone +'<br>' + address + '<br>Для ознакомления с каталогом посетите магазин.';
    }

    if (mapData[i].geo_lat) {
      placemarks[i] = new ymaps.Placemark(([Number(mapData[i].geo_lat), Number(mapData[i].geo_lon)]), {
        balloonContentHeader: mapData[i].title,
        balloonContent: balloonText,
      }, {
        preset: 'islands#darkGreenCircleIcon',
        iconColor: '#32A095',
      });

      map.geoObjects.add(placemarks[i]);
    }
  }

  //кластеризуем метки
  const clusterer = new ymaps.Clusterer({
    preset: 'twirl#greenClusterIcons',
    clusterIconColor: '#32A095',
    clusterHideIconOnBalloonOpen: false,
    geoObjectHideIconOnBalloonOpen: false,
    clusterDisableClickZoom: true,
    clusterBalloonContentLayout: 'cluster#balloonAccordionContent',
  });

  // if(placemarks) {
  //   clusterer.add(placemarks);
  //   map.geoObjects.add(clusterer);
  // }
}
